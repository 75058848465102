<template>
  <v-container>
    <v-card class="mx-auto col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <v-card-title class="d-flex justify-space-around p-0">
        <h2 class="font-weight-medium text-center">Autenticación Federada</h2>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row class="">
          <v-col class="d-flex align-center px-0" cols="8">
            <span class="text-subtitle-2 col-lg-6 col-md-6 col-sm-12 col-xs-12 pt-0 pb-0"
              :style="{ color: 'initial !important' }">Selecione un Proveedor</span>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pa-0">
              <select class="form-control" v-model="select">
                <option v-for="item in providers" v-bind:value="item.value" :key="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
          </v-col>

          <v-col class="d-flex align-center px-0 justify-content-end" cols="4">
            <v-btn class="mx-3 white--text blue-bootstrap" @click="getConnections">
              Generar conexión
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto col-lg-9 col-md-9 col-sm-12 col-xs-12 mt-5" v-for="record in connections" :key="record.id">
      <v-card-title class="d-flex justify-start py-0 pr-0">
        <div class="d-flex" style="justify-content: center;">
          <div style="margin-left: -17px;">
            <img alt="" :src="logo(record.provider)" :width="record.provider == 'ldap' ? '38rem' : ''" height="" />
          </div>

          <h3 class="font-weight-medium text-center ml-2">{{ capitalize(record.provider) }}</h3>
        </div>

        <v-spacer></v-spacer>

        <v-btn class="bg-white shadow-none" title="Desconectar cuenta" @click="disconnectAccount(record)">
          <v-icon color="red"> mdi-trash-can </v-icon>
        </v-btn>


        <v-btn class="bg-white shadow-none" @click="record.show = !record.show">
          <v-icon dark v-if="record.show"> mdi-chevron-up </v-icon>
          <v-icon dark v-if="!record.show"> mdi-chevron-down </v-icon>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <div v-show="record.show">
          <v-card-text>
            <v-row>
              <v-col class="d-flex align-center" cols="6" xs="6" sm="2" md="2">
                <span class="text-subtitle-2" :style="{ color: 'initial !important' }"
                  title="Número de identificaion de la petición">Nombre:</span>
              </v-col>

              <v-col cols="6" xs="6" sm="4" md="4">
                <v-text-field outlined dense hide-details type="text" v-model="record.item.givenName"
                  :disabled="true"></v-text-field>
              </v-col>

              <v-col class="d-flex align-center" cols="6" xs="6" sm="2" md="2">
                <span class="text-subtitle-2" :style="{ color: 'initial !important' }"
                  title="Medio de llegada">Alias:</span>
              </v-col>

              <v-col cols="6" xs="6" sm="4" md="4">
                <v-text-field outlined dense hide-details clearable type="text" v-model="record.item.alias"
                  :disabled="true"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex align-center" cols="6" xs="6" sm="2" md="2">
                <span class="text-subtitle-2" :style="{ color: 'initial !important' }"
                  title="Tipo de petición">Correo:</span>
              </v-col>

              <v-col cols="6" xs="6" sm="4" md="4">
                <v-text-field outlined dense hide-details clearable type="text" v-model="record.item.email"
                  :disabled="true"></v-text-field>
              </v-col>

              <v-col class="d-flex align-center" cols="6" xs="6" sm="2" md="2">
                <span class="text-subtitle-2" :style="{ color: 'initial !important' }"
                  title="Entidad afectada, cuestionada o requerida">Proveedor:</span>
              </v-col>

              <v-col cols="6" xs="6" sm="4" md="4">
                <v-text-field outlined dense hide-details clearable type="text" v-model="record.provider"
                  :disabled="true"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

        </div>
      </v-expand-transition>

    </v-card>
    <ModalForm :visible="loader.estado" @close="loader.estado = false" :message="loader.message" />
  </v-container>
</template>

<script>
import ModalForm from "../modal/ModalForm.vue";
import {
  getConnectionGoogle,
  getConnectionMicrosoft,
  getConnectionLdap
} from "../../plugins/passport-provider"
export default {
  components: {
    ModalForm,
  },
  data: () => {
    return {
      connections: [],
      select: null,
      providers: [
        { text: 'Selecione un proveedor', value: null },
        { text: 'Microsoft', value: 'microsoft' },
        { text: 'Google', value: 'google' },
        { text: 'Directorio activo', value: 'ldap' },
      ],
      loader: {
        estado: false,
        message: "",
      },
      logo_microsoft: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAABnRSTlMAAAAAAABupgeRAAAAWklEQVR4AWP4FKBEJPrip8wABrV7GOp3EYtGhgWjFoxaMGrBqAXf/OSIRD995CEWVO9hqN1FLBoOYNlnotF3iI7/O4lHQOVL3hONPo9agB2NWjBqwagFoxYAAB2zMZ7bQW2tAAAAAElFTkSuQmCC",
      logo_google: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAjVBMVEVHcEz////9/f38/Pz////4+fn9/f38/P3v8PH19vb///////87q1n8wAnrSz5HifXqOijsUUMfpUYvfvQwqVhDhvRnm/bg7ur74eDykIrznpj609DI5c/uZUj/78rG2PyzzPKJyJj5zMn/++37xSf81XhqsFCa0KV1v4f0kCGvtTCCyJyXt/hyofZDj9Yia4J9AAAAC3RSTlMAojhxnKGw/RkrfoV/rhcAAAFQSURBVDiNlVPZYoMgENQYRCIgclRNNKlpm/T+/88rl3jmofPEMuPu7LpE0X+wO8RpGh9222wW5wFxtuYntJUsaATzBSCaFV/SBhMryF9dm46xrjn5cMzh8p9KRsuypJSVTgLn/o6stKAa7Gqv9r6/kadMQ/NHX8R1u7f5Dc+aGsK6CbxPYY+dLk9rd12PnYQWz6SjbEJMW03M4Y2Qj2ZjFslg4ZUQcrZXTwMug4nUHDRPXqxAFBpKKfFsovSBwGiCYFFCGKiiEKHEYPLza/T2owpRDSaRa/MmeTXwla4j7AmFQd0kxtIrYKEK9R0G5Uy0HGPM+wrC6i41L+A4avez7kYhuYbO9ess+p8VARv0RuEgue0xB/OFaaX5GEvJsTMTFsYX0ZJ3XQP3rQ8nu7+5tLO1ztZrv3w7YM6DaIUMhCwQbDw9+z4SkKYgQdvsA/wBrYgleUhdXncAAAAASUVORK5CYII=",
      logo_ldap: "https://foroalfa.org/imagenes/ilustraciones/1121.jpg"
    };
  },
  created() {
    this.getInfoUser();
  },
  methods: {
    getInfoUser() {
      this.loader.estado = true;
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      let { user: user_id } = this.$store.state;
      let uri = "/passaport/user/" + user_id;

      this.axios
        .get(uri)
        .then((response) => {
          let data = response.data?.data

          this.connections = data.map((e) => {
            return { ...e, show: false }
          })

          this.loader.estado = false;
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.loader.estado = false;
        });
    },
    async getConnections() {
      let provider = this.select || null
      if (!provider) return alert("Selecione un proveedor");

      let microsoft = this.connections.find(e => e.provider == 'microsoft')
      let google = this.connections.find(e => e.provider == 'google')
      let ldap = this.connections.find(e => e.provider == 'ldap')

      if (provider == 'microsoft' && microsoft) {
        return alert("El usuario ya tiene una cuenta vincula con microsoft")
      }

      if (provider == 'google' && google) {
        return alert("El usuario ya tiene una cuenta vincula con google")
      }

      if (provider == 'ldap' && ldap) {
        return alert("El usuario ya tiene una cuenta vincula con un directorio activo")
      }

      let data = {}

      switch (provider) {
        case "microsoft":
          data = await getConnectionMicrosoft()
          break;
        case "google":
          data = await getConnectionGoogle()
          break;
        case "ldap":
          data = await getConnectionLdap()
          break;
      }

      this.updateConnection(data);
    },
    updateConnection(data) {
      this.loader.estado = true;
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      let uri = "/passaport/" + data?.id || "";
      let { company: cmp_id, user: user_id } = this.$store.state;

      this.axios
        .put(uri, { cmp_id, user_id })
        .then(() => {
          this.getInfoUser();
        })
        .catch((error) => {
          this.loader.estado = false;
        });
    },
    disconnectAccount(item) {
      this.loader.estado = true;
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      let uri = "/passaport/" + item?.provider_id || "";

      this.axios
        .delete(uri)
        .then(() => {
          this.getInfoUser();
        })
        .catch((error) => {
          this.loader.estado = false;
        });
    },
    logo(provider) {
      let logo = null

      switch (provider) {
        case 'microsoft': logo = this.logo_microsoft; break;
        case 'google': logo = this.logo_google; break;
        case 'ldap': logo = this.logo_ldap; break;
      }

      return logo
    },
    capitalize(e) {
      e = e == 'ldap' ? 'Directorio activo' : e
      return String(e).charAt(0).toUpperCase(0) + String(e).slice(1)
    }
  },
};
</script>